<template>
    <div class="full-height-layout fhf pb-10">
        <mercur-card class="ml-0 mr-0 fhf u-no-horizontal-overflow">
            <h2 class="font-weight-normal">Confirmation</h2>
            <div class="artwork-management-table">
                Add some content here...
            </div>
        </mercur-card>
        <mercur-card class="mt-15 ml-0 mr-0 text-right">
            <mercur-button
                :disabled="isSavingConfiguration"
                class="btn btn-raised btn-yellow text-uppercase">
                <span>Save</span>
                <i class="fas fa-forward artwork-management-save-icon ml-1"></i>
            </mercur-button>
        </mercur-card>
    </div>
</template>

<script>
import ArtworkManagementConfigurationsMixin from '@/views/artwork_management/configurations/ArtworkManagementConfigurationsMixin'

export default {
    name: 'ArtworkManagementConfirmation',
    mixins: [ ArtworkManagementConfigurationsMixin ],
}
</script>
